import React from "react";
import { Bar } from "react-chartjs-2"; // Import the Bar component
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BinChart = ({ bins = [] }) => {
  console.log("Bins received in BinChart:", bins);

  // if (!Array.isArray(bins) || bins.length === 0) {
  //   return <p>No data available to display</p>;
  // }

  const chartData = {
    labels: bins.map((bin) => bin.binRange), // X-axis labels (bin ranges)
    datasets: [
      {
        label: "HR Points",
        data: bins.map((bin) => bin.hrPoints),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Makes the chart responsive to its container
    plugins: {
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        formatter: (value) => value.toLocaleString(),
        font: { weight: "bold" },
      },
    },
    scales: {
      x: {
        title: { display: true, text: "Bin Range" },
      },
      y: {
        title: { display: true, text: "HR Points" },
        beginAtZero: true, // Ensure Y-axis starts at 0
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Bar data={chartData} options={chartOptions} /> {/* Render the bar chart */}
    </div>
  );
};

export default BinChart;
