import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SiteLineChart = ({ data }) => {
  // Handle undefined or empty data gracefully
  const siteCounts = data?.siteCounts || {};

  // Extract labels and corresponding data dynamically
  const labels = Object.keys(siteCounts); // Get unique site names dynamically
  const counts = Object.values(siteCounts); // Get corresponding counts dynamically

  const lineDataSite = {
    labels: labels, // Use dynamically generated labels
    datasets: [
      {
        label: "Site Counts", // Add a label for the dataset
        data: counts, // Use dynamically generated counts
        borderColor: "rgba(75, 192, 192, 1)", // Customize the line color
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Optional fill color
        tension: 0.4, // Add some curve to the line
        pointBackgroundColor: "rgba(75, 192, 192, 1)", // Point color
        pointBorderColor: "#fff", // Point border color
        pointHoverBackgroundColor: "#fff", // Point hover effect
        pointHoverBorderColor: "rgba(75, 192, 192, 1)", // Point hover border
      },
    ],
  };

  const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true, // Show legend
        position: "top", // Legend position
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Sites",
        },
      },
      y: {
        title: {
          display: true,
          text: "Counts",
        },
        beginAtZero: true, // Start y-axis at zero
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}> {/* Ensure the chart is responsive */}
      <Line data={lineDataSite} options={options} />
    </div>
  );
};

export default SiteLineChart;
