import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const SummaryTable = ({ data = {} }) => {
  // Extract rows dynamically based on the data keys (HR, RR, etc.)
  const rows = Object.keys(data);

  // Helper to get totals for each column (R1, R2, R3)
  const getColumnData = (row, column) => {
    const rowData = data[row];
    return rowData && rowData[column] !== undefined
      ? rowData[column]
      : "N/A"; // Default to "N/A" if the data is missing
  };

  // Guard for empty data
  if (!rows || rows.length === 0) {
    return (
      <Typography variant="h6" style={{ padding: "16px", textAlign: "center" }}>
        No data available to display.
      </Typography>
    );
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: "16px" }}>
      <Typography variant="h5" component="h2" style={{ padding: "16px" }}>
        Summary Table
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell align="center">R1/Subjects</TableCell>
            <TableCell align="center">R2/Subjects</TableCell>
            <TableCell align="center">R3/Subjects</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
              <TableCell align="center">{getColumnData(row, "R1")}</TableCell>
              <TableCell align="center">{getColumnData(row, "R2")}</TableCell>
              <TableCell align="center">{getColumnData(row, "R3")}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
