import React, { useEffect, useState } from "react";
import { fetchSummaryData, processSummaryData, fetchGenderData, processGenderData, processRaceData, processSiteData, processAgeData, processBMIData } from "./googleSheetsAPI";
import { Grid, Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, Box, TextField, CircularProgress } from "@mui/material";
import "chart.js/auto";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GenderPieChart from "./GenderPieChart";
import RacePieChart from "./RacePieChart";
import SummaryTable from "./SummaryTable";
import BinChart from './BinChart';
import SiteLineChart from "./SiteLineChart";
import AgeLineChart from "./AgeLineChart";
import BMIBarChart from "./BMIBinChart";
import { fetchAndProcessTableData } from "./SummaryTableAPI";





const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState(null);
const [summaryData, setSummaryData] = useState({
    bins: [],
    totals: null,
  });
  
  const SPREADSHEET_IDS = {
    R1: {
      RR: "1KrZrizE_BkpXi0Y1uFVYJM-fF2XupuH6BJCxPiRXWhI",
      HR: "1q4acZRfoGgMPzAQY7OIdwVD0EaEhKxlqSSnIvIa_feM",
      NcBP: "1OGeEvs_ishYco5Uza4HTqdf4cBJ6Q2nj_XhdnErnk4c",
      Occupancy: "1AbC12345XyZ_example_SpreadsheetId",
      Processibility: "a"
    },
    R2: {
      HR: "1qyOZrjjFsrM_V9jft9nsleHgM46LJYKhXF9jpYH3Yjg",
      RR: "29__heErzYyGGa7IX4cGWWWXeQkgcVrlJiEHuUJjX-BQ",
      NcBP: "2OGeEvs_ishYco5Uza4HTqdf4cBJ6Q2nj_XhdnErnk4c",
      Occupancy: "2AbC12345XyZ_example_SpreadsheetId",
      Processibility: "b"
    },
    R3: {
      HR: "3KrZrizE_BkpXi0Y1uFVYJM-fF2XupuH6BJCxPiRXWhI",
      RR: "39__heErzYyGGa7IX4cGWWWXeQkgcVrlJiEHuUJjX-BQ",
      NcBP: "3OGeEvs_ishYco5Uza4HTqdf4cBJ6Q2nj_XhdnErnk4c",
      Occupancy: "3AbC12345XyZ_example_SpreadsheetId",
      Processibility: "c"
    },
  };



  const [selectedR, setSelectedR] = useState("R1"); // State for R1, R2, R3 dropdown
  const [selectedCategory, setSelectedCategory] = useState("HR"); // State for category dropdown
  const [selectedDate, setSelectedDate] = useState(null); // State for selected date
  const [selectedWeek, setSelectedWeek] = useState(null); // State for selected week
  const [genderData, setGenderData] = useState(null); // State for gender data
  const [raceData, setRaceData] = useState(null); // State for race data
  const [siteData, setSiteData] = useState(null); // State for site data
  const [ageData, setAgeData] = useState(null); // State for age data
  const [BMIData, setBMIData] = useState(null); // State for bmi data







  // Fetch and process data when component mounts
  useEffect(() => {
    const getData = async () => {
        const SPREADSHEET_ID = SPREADSHEET_IDS[selectedR]?.[selectedCategory]; // Dynamically pick spreadsheet ID
        if (!SPREADSHEET_ID) {
          console.error("Invalid selection for R value or category");
          return;
        }  
      const summaryRawData = await fetchSummaryData(SPREADSHEET_ID);
      const genderRawData = await fetchGenderData(SPREADSHEET_ID);
      const fetchedTableData = await fetchAndProcessTableData();
      
      
  
      const processedSummaryData = processSummaryData(summaryRawData) || {};
      const processedGenderData = processGenderData(genderRawData) || {};
      const processedRaceData = processRaceData(genderRawData) || {};
      const processedSiteData = processSiteData(genderRawData) || {};
      const processedAgeData = processAgeData(genderRawData) || {};
      const processedBMIData = processBMIData(genderRawData) || {};
      
      setTimeout(() => {  
  
      setSummaryData({
        bins: processedSummaryData.bins || [],
        totals: processedSummaryData.totals || null,
      });
      setGenderData(processedGenderData);
      setRaceData(processedRaceData);
      setSiteData(processedSiteData);
      setAgeData(processedAgeData);
      setBMIData(processedBMIData);
      setTableData(fetchedTableData);
    },2000);
    };
    getData();
  }, [selectedR, selectedCategory]);
  

  // Display loading message until data is available
  if (!genderData || !raceData) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Makes the loader centered vertically and horizontally
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2, color: 'text.secondary' }}>
          Loading data, please wait...
        </Typography>
      </Box>
    );
  }

  // Handle R Value dropdown change
  const handleRChange = (event) => {
    setSelectedR(event.target.value);
    setLoading(true);
  };

  // Handle Category dropdown change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setLoading(true);
  };

  // Handle DatePicker value change
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    if (newDate) {
      const weekNumber = getWeekNumber(newDate);
      setSelectedWeek(weekNumber);
    }
  };

  // Function to get the week number from a Date object
  const getWeekNumber = (date) => {
    const currentDate = new Date(date);
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + 1) / 7);
  };

  // Data configurations for various charts
  const barData = {
    labels: data?.bins?.map((bin) => bin.binRange) || [],
    datasets: [
      {
        label: "HR Points",
        data: data?.bins?.map((bin) => bin.hrPoints) || [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };
  
  // Consistent chart options for both Bar and Pie charts
  const chartOptions = {
    plugins: {
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        formatter: (value) => value.toLocaleString(),
        font: { weight: "bold" },
      },
    },
    maintainAspectRatio: true, // Maintains a fixed aspect ratio
    aspectRatio: 2, // Adjust as needed (2 is width:height ratio)
    responsive: true,
    hover: {
      mode: 'nearest',
      animationDuration: 0, // Disable animation on hover
    },
    scales: {
      x: { beginAtZero: true },
      y: { 
        beginAtZero: true, 
        title: { display: true, text: "HR Points" },
        ticks: { stepSize: 1 },
        animations: {
          tension: { duration: 0 }, // Disable animation for tension
        },
      },
    },
    animation: {
      duration: 0, // General animation duration
    },
  };


  const pieOptions = {
    ...chartOptions,
    plugins: { datalabels: { display: true } },
  };

  // Updated chart components array (removed unwanted HR Points over bins chart)
  const chartComponents = [
    { chart: <BinChart bins={summaryData.bins || []} />, title: "HR Points Distribution" },
    { chart: <GenderPieChart data={genderData} />, title: "Gender Distribution" },
    { chart: <SiteLineChart data={siteData || []} />, title: "Study Location" },
    { chart: <BMIBarChart BMIData={BMIData || []} />, title: "BMI Distribution" },
    { chart: <RacePieChart data={raceData} />, title: "Race Distribution" },
    { chart: <AgeLineChart ageData={ageData || []} />, title: "Age Distribution" },
  ];
  
  

  return (
        <div>
          {/* Summary Table Section */}
          <Grid item xs={12}>
            <Card>
                <CardContent>
                    <SummaryTable data={tableData} />
                </CardContent>
            </Card>
          </Grid>
      
          {/* Select Parameters Section */}
          <div style={{ padding: "20px" }}>
            <Grid container spacing={3} style={{ marginBottom: "20px" }}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Select Parameters
                    </Typography>
                    <Grid container spacing={2}>
                      {/* R1, R2, R3 Dropdown */}
                      <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel>Class Type</InputLabel>
                          <Select value={selectedR} onChange={handleRChange}>
                            <MenuItem value="R1">R1</MenuItem>
                            <MenuItem value="R2">R2</MenuItem>
                            <MenuItem value="R3">R3</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
      
                      {/* Category Dropdown */}
                      <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel>Parameters</InputLabel>
                          <Select value={selectedCategory} onChange={handleCategoryChange}>
                            <MenuItem value="HR">HR</MenuItem>
                            <MenuItem value="RR">RR</MenuItem>
                            <MenuItem value="NcBP">NcBP</MenuItem>
                            <MenuItem value="Occupancy">Occupancy</MenuItem>
                            <MenuItem value="Processibility">Processibility</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                        
                      {/* Week Selector */}
                      <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Select Week"
                              value={selectedDate}
                              onChange={handleDateChange}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
      
            {/* Charts Section */}
            <Grid container spacing={3}>
              {chartComponents.map(({ chart, title }, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {title}
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          height: "400px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {chart}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      );
    }

export default Dashboard;
