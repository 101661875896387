const API_KEY = 'AIzaSyAPiupDIPDw-hBbJk20e6Oxthx21OhEBlk';
const BASE_URL = `https://sheets.googleapis.com/v4/spreadsheets`;

// Updated SPREADSHEET_IDS to use R1, R2, R3
const SPREADSHEET_IDS = {
    R1: {
        RR: "1KrZrizE_BkpXi0Y1uFVYJM-fF2XupuH6BJCxPiRXWhI",
        HR: "1q4acZRfoGgMPzAQY7OIdwVD0EaEhKxlqSSnIvIa_feM",
        NcBP: "1OGeEvs_ishYco5Uza4HTqdf4cBJ6Q2nj_XhdnErnk4c",
        Occupancy: "a",
        Processibility: "z"
    },
    R2: {
        RR: "spreadsheet_id_R2_RR", // Replace with actual ID
        HR: "1qyOZrjjFsrM_V9jft9nsleHgM46LJYKhXF9jpYH3Yjg", // Replace with actual ID
        NcBP: "spreadsheet_id_R2_NcBP", // Replace with actual ID
        Occupancy: "spreadsheet_id_R2_Occupancy", // Replace with actual ID
        Processibility: "w"
    },
    R3: {
        RR: "spreadsheet_id_R3_RR", // Replace with actual ID
        HR: "spreadsheet_id_R3_HR", // Replace with actual ID
        NcBP: "spreadsheet_id_R3_NcBP", // Replace with actual ID
        Occupancy: "spreadsheet_id_R3_Occupancy", // Replace with actual ID
        Processibility: "y"
    },
};

// Fetch data for a specific spreadsheet
const fetchSpreadsheetData = async (spreadsheetId) => {
    try {
        const response = await fetch(`${BASE_URL}/${spreadsheetId}/values/Summary!A1:B14?key=${API_KEY}`);
        const data = await response.json();
        return data.values || [];
    } catch (error) {
        console.error(`Error fetching data for spreadsheet ${spreadsheetId}:`, error);
        return [];
    }
};

// Process data to extract Points and Subjects
const processSummaryData = (data) => {
    if (!data || data.length < 2) return { Points: 0, Subjects: 0 };

    return {
        Points: parseInt(data[1][1], 10) || 0, // Points data is in column B (index 1)
        Subjects: parseInt(data[1][0], 10) || 0, // Subjects data is in column A (index 0)
    };
};

// Fetch and process all data dynamically based on R1, R2, R3, and parameter
export const fetchAndProcessTableData = async () => {
    const formattedData = {
        HR: {},
        RR: {},
        NcBP: {},
        Occupancy: {},
        Processibility: {}
    };

    // Loop over R1, R2, R3 to fetch data dynamically
    for (const [region, parameters] of Object.entries(SPREADSHEET_IDS)) {
        for (const [param, spreadsheetId] of Object.entries(parameters)) {
            const data = await fetchSpreadsheetData(spreadsheetId);
            const totals = processSummaryData(data);

            // Save as Points/Subjects format
            formattedData[param][region] = `${totals.Points}/${totals.Subjects}`;
        }
    }

    return formattedData;
};
