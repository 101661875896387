import React from "react";
import { Pie } from "react-chartjs-2";

const RacePieChart = ({ data }) => {
  // Extract race labels and counts dynamically
  const raceCounts = data?.raceCounts || {};
  const raceLabels = Object.keys(raceCounts);
  const raceValues = Object.values(raceCounts);

  // Check if there are no values to display
  const noData = raceLabels.length === 0 || raceValues.every(value => value === 0);

  if (noData) {
    return <div style={{ textAlign: "center", padding: "20px" }}>No value found</div>;
  }

  const pieDataRace = {
    labels: raceLabels, // Dynamically populated labels
    datasets: [
      {
        data: raceValues, // Dynamically populated data
        backgroundColor: [
          "#36A2EB", // Blue
          "#FF6384", // Red
          "#FFCE56", // Yellow
          "#4BC0C0", // Teal
          "#9966FF", // Purple
          "#FF9F40", // Orange
          "#C9CBCF", // Gray
        ].slice(0, raceLabels.length), // Use only as many colors as needed
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "400px" }}> {/* Ensure responsiveness */}
      <Pie data={pieDataRace} />
    </div>
  );
};

export default RacePieChart;
