import React from "react";
import { Line } from "react-chartjs-2";

const AgeLineChart = ({ ageData }) => {
  // Extract ageBins from the nested structure or set default
  const ageBins = ageData?.ageBins || { NoValueFound: 0 };

  // Check if the only key in ageBins is "NoValueFound"
  const hasNoData = Object.keys(ageBins).length === 1 && ageBins.NoValueFound === 0;
  // Configure chart data
  const data = {
    labels: hasNoData ? ["No Value Found"] : Object.keys(ageBins), // Age ranges or fallback label
    datasets: [
      {
        label: "Age Distribution",
        data: hasNoData ? [0] : Object.values(ageBins), // Corresponding counts or fallback value
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Fill color under line
        tension: 0.4, // Curve the line slightly
        pointBackgroundColor: "rgba(75, 192, 192, 1)", // Color of points
        pointBorderColor: "#fff", // Border color of points
        pointHoverBackgroundColor: "#fff", // Hover effect for points
        pointHoverBorderColor: "rgba(75, 192, 192, 1)", // Hover border
      },
    ],
  };

  // Configure chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true, // Display the legend
        position: "top", // Position of the legend
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: hasNoData ? "" : "Age Ranges", // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: hasNoData ? "" : "Counts", // Y-axis label
        },
        beginAtZero: true, // Start the Y-axis at zero
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Line data={data} options={options} />
      {hasNoData && (
        <p style={{ textAlign: "center", color: "#888", marginTop: "200px" }}>
          No data available for Age Distribution.
        </p>
      )}
    </div>
  );
};

export default AgeLineChart;
