import React from "react";
import { Pie } from "react-chartjs-2";

const GenderPieChart = ({ data }) => {
  const pieData = {
    labels: ["Male", "Female", "No Data"],
    datasets: [
      {
        data: [data.genderCounts.Male, data.genderCounts.Female, data.genderCounts.NoData],
        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
      },
    ],
  };

  return <Pie data={pieData} />;
};

export default GenderPieChart;
