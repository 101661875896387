import React from "react";
import { Bar } from "react-chartjs-2"; // Import the Bar component
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register required Chart.js modules
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BMIBarChart = ({ BMIData = {} }) => {
  // Extract BMIBins from the nested structure
  const BMIBins = BMIData?.BMIBins || {};

  // Check if BMIBins is valid and has data
  if (Object.keys(BMIBins).length === 0) {
    return <p>No BMI data available to display</p>;
  }

  const data = {
    labels: Object.keys(BMIBins), // BMI ranges
    datasets: [
      {
        label: "BMI Distribution",
        data: Object.values(BMIBins), // Corresponding counts
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Bar fill color
        borderColor: "rgba(75, 192, 192, 1)", // Bar border color
        borderWidth: 1, // Border thickness
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Make chart responsive
    plugins: {
      legend: {
        display: true, // Display the legend
        position: "top", // Position of the legend
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "BMI Ranges", // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: "Counts", // Y-axis label
        },
        beginAtZero: true, // Start the Y-axis at zero
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Bar data={data} options={options} /> {/* Pass correct `data` prop */}
    </div>
  );
};

export default BMIBarChart;
