const API_KEY = 'AIzaSyAPiupDIPDw-hBbJk20e6Oxthx21OhEBlk';
const BASE_URL = '';



// Fetch summary data
export const fetchSummaryData = async (SPREADSHEET_ID) => {
  const BASE_URL = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values`;
  try {
    const response = await fetch(`${BASE_URL}/Summary!A1:B14?key=${API_KEY}`);
    const data = await response.json();
    console.log("Fetched raw data:", data); 
    return data.values;
  } catch (error) {
    console.error("Error fetching summary data:", error);
    return [];
  }
};

export const processSummaryData = (data) => {
  if (!data || data.length < 2) return { totals: {}, bins: [] };

  console.log("Print", {data});

  const totals = {
    subjects: parseInt(data[1][0], 10),
    hrPoints: parseInt(data[1][1], 10),
  };

  const bins = data.slice(4).map(row => ({
    binRange: row[0],
    hrPoints: parseInt(row[1], 10),
  })).filter(bin => !isNaN(bin.hrPoints));  

  return { totals, bins };
};

// Fetch gender data from the 'Demographics' sheet
export const fetchGenderData = async (SPREADSHEET_ID) => {
  const BASE_URL = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values`;
  try {
    const response = await fetch(`${BASE_URL}/Demographics!A1:CW1000?key=${API_KEY}`);
    const data = await response.json();
    console.log("Fetched raw gender data:", data);
    return data.values;
  } catch (error) {
    console.error("Error fetching gender data:", error);
    return [];
  }
};

// Fetch and process Gender data
export const processGenderData = (data) => {
  if (!data || data.length < 2) return { genderCounts: { Male: 0, Female: 0 } };

  // Find the index of the "Gender" column dynamically
  const genderColumnIndex = data[0].indexOf('Gender');
  if (genderColumnIndex === -1) {
    console.error('Gender column not found');
    return { genderCounts: { Male: 0, Female: 0 } };
  }

  const genderData = data.slice(1).map(row => row[genderColumnIndex]);

  const genderCounts = genderData.reduce((acc, gender) => {
    if (gender === 'Male') {
      acc['Male'] = (acc['Male'] || 0) + 1;
    } else if (gender === 'Female') {
      acc['Female'] = (acc['Female'] || 0) + 1;
    } else {
      acc['NoData'] = (acc['NoData'] || 0) + 1;
    }
    return acc;
  }, {});

  return { genderCounts };
};


// Fetch and process Race data dynamically
export const processRaceData = (data) => {
  if (!data || data.length < 2) return { raceCounts: {} }; // Handle empty or invalid data

  // Find the index of the "Race" column dynamically
  const raceColumnIndex = data[0].indexOf('Race');
  if (raceColumnIndex === -1) {
    console.error('Race column not found');
    return { raceCounts: {} };
  }

  const raceData = data.slice(1).map(row => row[raceColumnIndex]);

  // Count occurrences of each race dynamically
  const raceCounts = raceData.reduce((acc, race) => {
    if (race) {
      acc[race] = (acc[race] || 0) + 1; // Increment the count or initialize to 1
    } else {
      acc['Unknown/Not Reported'] = (acc['Unknown/Not Reported'] || 0) + 1; // Handle undefined or blank values
    }
    return acc;
  }, {});

  return { raceCounts };
};

// Fetch and process Site data dynamically
export const processSiteData = (data) => {
  // Handle empty or invalid data
  if (!data || data.length < 2) return { siteCounts: {} };

  // Find the index of the "Study Location" column dynamically
  const siteColumnIndex = data[0].indexOf('Study Location');
  if (siteColumnIndex === -1) {
    console.error('Site column not found');
    return { siteCounts: {} };
  }

  // Extract site data (excluding the header row)
  const siteData = data.slice(1).map(row => row[siteColumnIndex]);

  // Dynamically count occurrences of each site
  const siteCounts = siteData.reduce((acc, site) => {
    if (site) {
      acc[site] = (acc[site] || 0) + 1; // Increment count for each site
    }
    return acc;
  }, {});

  return { siteCounts };
};


// Fetch and process Age data
export const processAgeData = (data) => {
  if (!data || data.length < 2) return { ageBins: { NoValueFound: 0 } }; // Return a message if no data

  // Find the index of the "Age" column dynamically
  const ageColumnIndex = data[0].indexOf('Age (yrs)');
  if (ageColumnIndex === -1) {
    console.error('Age column not found');
    return { ageBins: { NoValueFound: 0 } }; // Return a message if Age column is not found
  }

  const ageData = data.slice(1).map(row => parseInt(row[ageColumnIndex], 10)); // Convert to integers

  // Remove NaN values or any invalid age data
  const validAgeData = ageData.filter(age => !isNaN(age));

  // If no valid age data is found
  if (validAgeData.length === 0) {
    return { ageBins: { NoValueFound: 0 } };
  }

  // Dynamically define age ranges
  const ageRanges = [
    { range: "10-20", min: 10, max: 20 },
    { range: "20-30", min: 20, max: 30 },
    { range: "30-40", min: 30, max: 40 },
    { range: "40-50", min: 40, max: 50 },
    { range: "50-60", min: 50, max: 60 },
    { range: "60-70", min: 60, max: 70 },
    { range: "70-80", min: 70, max: 80 },
    { range: "Others", min: 80, max: Infinity } // Covers age >= 80 and invalid entries
  ];

  // Create an empty object for bins
  const ageBins = ageRanges.reduce((acc, { range }) => {
    acc[range] = 0; // Initialize all bins to 0
    return acc;
  }, {});

  // Populate the age bins
  validAgeData.forEach(age => {
    const bin = ageRanges.find(({ min, max }) => age >= min && age < max);
    if (bin) {
      ageBins[bin.range] += 1;
    }
  });

  return { ageBins };
};



// Fetch and process BMI data
export const processBMIData = (data) => {
  if (!data || data.length < 2) return { BMIBins: { "0-18.50": 0, "18.50-24.99": 0, "24.99-29.99": 0, "29.99-34.99": 0, "34.99-39.99": 0, ">=39.99": 0, Others: 0 } };

  // Find the index of the "Age" column dynamically
  const BMIColumnIndex = data[0].indexOf('BMI');
  if (BMIColumnIndex === -1) {
    console.error('BMI column not found');
    return { BMIBins: { "0-18.50": 0, "18.50-24.99": 0, "24.99-29.99": 0, "29.99-34.99": 0, "34.99-39.99": 0, ">=39.99": 0, Others: 0 } };
  }

  const BMIData = data.slice(1).map(row => parseInt(row[BMIColumnIndex], 10)); // Convert to integers

  const BMIBins = BMIData.reduce(
    (acc, age) => {
      if (age >= 0 && age < 18.50) {
        acc["0-18.50"] += 1;
      } else if (age >= 18.50 && age < 24.99) {
        acc["18.50-24.99"] += 1;
      } else if (age >= 24.99 && age < 29.99) {
        acc["24.99-29.99"] += 1;
      } else if (age >= 29.99 && age < 34.99) {
        acc["29.99-34.99"] += 1;
      } else if (age >= 34.99 && age < 39.99) {
        acc["34.99-39.99"] += 1;
      } else if (age >= 39.99) {
        acc[">=39.99"] += 1;
      } else {
        acc.Others += 1; // Includes missing, non-numeric, or out-of-range values
      }
      return acc;
    },
    {
      "0-18.50": 0, 
      "18.50-24.99": 0, 
      "24.99-29.99": 0, 
      "29.99-34.99": 0, 
      "34.99-39.99": 0, 
      ">=39.99": 0, 
      Others: 0 }
  );

  return { BMIBins };
};

